
<template>
    <ButtonList>
        <li
            v-for="agendaType in agendaTypes"
            :key="`agenda-type-${agendaType.key}`"
        >
            <FilterButton
                :value="agendaType.id"
                :label="agendaType.name"
                param="agenda-type"
                size="small"
            />
        </li>
    </ButtonList>
</template>

<script setup>
import agendaTypes from '~/constants/agenda-types';
</script>
