
<template>
    <ButtonList>
        <li
            v-for="dossier in dossierStore.trainingDossierFilterList"
            :key="`dossier-id-${dossier.id}`"
        >
            <FilterButton
                :value="dossier.id"
                :label="dossier.title"
                param="dossier-id"
                size="small"
            />
        </li>
    </ButtonList>
</template>

<script setup>
import { useDossierStore } from '~/store/dossiers';

const dossierStore = useDossierStore();
</script>
