<template>
    <DossierSelector
        :selected-dossier-ids="selectedDossierIds"
    >
        <template #dossier-button="{ dossier }">
            <FilterButton
                :value="dossier.id"
                :label="dossier.title"
                param="dossier-id"
                size="small"
            />
        </template>

        <template #select-theme-button="{ dossierIds }">
            <BaseButton
                class="base-button--tertiary"
                size="small"
                @click="selectTheme(dossierIds)"
            >
                Selecteer alle dossiers
            </BaseButton>
        </template>
    </DossierSelector>
</template>

<script setup>
const { activeValues, activateFilters } = useFilters('dossier-id');

const selectedDossierIds = computed(() => {
    return activeValues;
});

const selectTheme = function(dossierIds) {
    activateFilters(dossierIds);
};

</script>
