

<template>
    <ButtonList v-if="articleTypeFilters">
        <li
            v-for="articleType in articleTypeFilters"
            :key="`article-type-${articleType.id}`"
        >
            <FilterButton
                :value="articleType.id"
                :label="articleType.title"
                param="article-type-id"
                size="small"
            />
        </li>
    </ButtonList>
</template>

<script setup>
import { useArticleStore } from '~/store/article';

const articleStore = useArticleStore();

const articleTypeFilters = computed(() => articleStore.articleTypes);
</script>
