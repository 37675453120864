<template>
    <BaseButton
        :icon="isFilterActive ? 'check' : 'plus'"
        :class="{
            'base-button--secondary' : !isFilterActive
        }"
        :title="isFilterActive ? `Deselecteer ${ label }` : `Selecteer ${ label }`"
        size="small"
        @click="toggleFilter(value)"
    >
        {{ label }}
    </BaseButton>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    param: {
        type: String,
        default: null,
    },
    value: {
        type: [String, Number],
        default: null,
    }
});

const { isActive, toggleFilter } = useFilters(props.param);

// Check if the filter is active
const isFilterActive = computed(() => {
    return isActive(props.value);
});
</script>
